import model from './model';
import { CollectionPageWidgetComponentIds as ComponentIds } from './config/constants';
import { ProjectInCollection } from '@wix/ambassador-portfolio-projects-v1-project/types';
import { Collection } from '@wix/ambassador-portfolio-collections-v1-collection/types';
import { getPortfolioBaseUrl } from '../../utils/urlsUtils';
import { getPageViewMode, isSeoTagsEnabled } from '../../utils/viewerUtils';
import { portfolioPageViewed } from '@wix/bi-logger-portfolio-users/v2';
import {
  getCollectionBySlug,
  getCollectionProjects,
} from '../../services/dataServices';

export interface CollectionPageData {
  collection?: Collection;
  collectionProjects?: ProjectInCollection[];
}

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  const { isViewer, isEditor } = flowAPI.environment;
  const { httpClient } = flowAPI;
  const biLogger = flowAPI.essentials.biLoggerFactory().logger();

  try {
    // @ts-expect-error
    console.log('HttpClient baseUrl ' + flowAPI.httpClient.opts.baseURL);
    // @ts-expect-error
    console.log('HttpClient SSR ' + flowAPI.httpClient.opts.isSSR);
  } catch (e) {
    console.error('Logs error', e);
  }
  let components: ReturnType<typeof getComponents>;
  let routerData: any = {};
  let collectionPageData: CollectionPageData = {};

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    navigationWidget: $w(ComponentIds.NavigationWidget),
    collectionWidget: $w(ComponentIds.CollectionWidget),
    emptyStateWidget: $w(ComponentIds.EmptyStateWidget),
  });

  const showEmptyStateIfNeeded = async () => {
    if (!isEditor) return false;
    let isEmptyStateDisplayed = false;
    if (routerData && !collectionPageData.collection?.id) {
      await components.collectionWidget.collapse();
      await components.emptyStateWidget.expand();
      isEmptyStateDisplayed = true;
    } else if (routerData && collectionPageData.collection?.id) {
      await components.emptyStateWidget.collapse();
      await components.collectionWidget.expand();
    }
    return isEmptyStateDisplayed;
  };
  const initCollectionPageData = async () => {
    components.navigationWidget.data = {
      hideNextButton: true,
      hideBackButton: false,
    };
    components.collectionWidget.data = {
      collection: collectionPageData.collection,
      projects: collectionPageData.collectionProjects?.map((p) => p.project),
    };
  };

  const handleInteractions = () => {
    if (!isEditor) {
      const url = getPortfolioBaseUrl(flowAPI);
      components.navigationWidget.onBackClicked(() => {
        flowAPI.controllerConfig.wixCodeApi.location.to &&
          flowAPI.controllerConfig.wixCodeApi.location.to(url);
      });
    }
  };

  const initData = async () => {
    routerData =
      await flowAPI.controllerConfig.wixCodeApi.window.getRouterPublicData();

    collectionPageData = routerData?.collectionPageData || {};

    if (!collectionPageData.collection && isViewer) {
      collectionPageData.collection = (await getCollectionBySlug(
        flowAPI,
      )) as Collection;
    }

    if (
      !collectionPageData.collectionProjects &&
      collectionPageData.collection?.id
    ) {
      collectionPageData.collectionProjects = await getCollectionProjects(
        httpClient,
        collectionPageData.collection.id,
      );
    }
  };

  const initCollectionPage = async () => {
    await components.emptyStateWidget.collapse();
    await initData();
    const isEmptyStateDisplayed = await showEmptyStateIfNeeded();
    if (!isEmptyStateDisplayed) {
      handleInteractions();
      await initCollectionPageData();
    }
  };

  return {
    pageReady: async () => {
      components = getComponents();
      const isWidgetOnStage = !!components.topContainer.id;
      if (isWidgetOnStage) {
        await initCollectionPage();

        biLogger.report(
          portfolioPageViewed({
            pageName: 'collection',
            viewMode: getPageViewMode(flowAPI),
          }),
        );
        if (isSeoTagsEnabled(flowAPI)) {
          flowAPI.controllerConfig.wixCodeApi.seo.renderSEOTags({
            itemType: 'PORTFOLIO_COLLECTIONS',
            itemData: {
              collection: collectionPageData.collection,
              collectionProjects: collectionPageData.collectionProjects,
            },
            seoData: {},
          });
        }
      }
    },
    exports: {},
  };
});
